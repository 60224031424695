<template>
  <div>
    <!-- Export Button in the upper right -->
    <div class="d-flex justify-content-end mb-3">
      <button 
        class="btn btn-outline-success"
        @click="exportTosfList"
      >
        <i class="bi bi-file-earmark-arrow-down mr-1"></i>
        Export List
      </button>
    </div>

    <div id="">
      <CCard class="rounded shadow-sm">
        <CCardBody>
          <div class="d-flex justify-content-start align-items-center">
            <div class="col-3 pl-0">
              <input
                type="search"
                class="form-control"
                placeholder="Try searching the data"
                v-model="searchValue"
              />
            </div>
  
            <div class="col-2 pl-2">
              <select v-model="selectedAY" class="form-control">
                <option value="">All Academic Years</option>
                <option v-for="ay in uniqueAcademicYears" :key="ay" :value="ay">
                  {{ ay }}
                </option>
              </select>
            </div>
  
            <div class="col-2 pl-2">
              <select v-model="selectedType" class="form-control">
                <option value="">All Types</option>
                <option value="firstYear">First Year Level</option>
                <option value="allYear">All Year Level</option>
              </select>
            </div>
  
            <div class="col-2 pl-2">
              <select v-model="selectedStatus" class="form-control">
                <option value="">All Status</option>
                <option value="Pending">Pending</option>
                <option value="Reviewed">Reviewed</option>
                <option value="OnProcess">On Process</option>
                <option value="Evaluated">Evaluated</option>
                <option value="Rejected">Rejected</option>
              </select>
            </div>
  
            <div>
              <button
                class="btn btn-outline-secondary text-dark mr-2"
                @click="refreshData"
                :disabled="refreshLoader"
              >
                <i class="bi bi-arrow-clockwise mr-1"></i> Refresh
              </button>
              <div
                v-if="refreshLoader"
                class="spinner-border spinner-border-sm"
                role="status"
              >
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
          <table
            id="table-of-programs"
            class="table table-hover table-bordered table-sm mt-3"
          >
            <thead>
              <tr>
                <th>APP NO.</th>
                <th>HEI CODE</th>
                <th>HEI NAME</th>
                <th>ACADEMIC YEAR</th>
                <th>CONTACT PERSON</th>
                <th>EMAIL</th>
                <th>CONTACT NO.</th>
                <th>STATUS</th>
                <th>TYPE OF INCREASE</th>
                <th>REMARKS</th>
                <th>DATE SUBMITTED</th>
                <th>UPDATED BY</th>
                <th>ACTIONS</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="text-center"
                v-if="!filteredList.length && !tableLoader"
              >
                <td colspan="11">
                  No results found
                </td>
              </tr>
              <tr class="text-center" v-if="tableLoader">
                <td colspan="11">
                  <div class="spinner-border spinner-border-sm mr-1"></div>
                  Loading
                </td>
              </tr>
              <tr
                v-for="(program, index) in paginatedList"
                :key="program.id"
                @click.stop="pushRoute(program)"
              >
                <th>CHED13-TOSF-{{  program.id }}</th>
                <th>{{ program.hei_code }}</th>
                <td>{{ program.name }}</td>
                <td>
                  {{ program.academic_year }}
                </td>
                <td>{{ program.contact_person }}</td>
                <td>{{ program.email }}</td>
                <td>{{ program.phone_no }}</td>
                <td>{{ program.status }}</td>
                <td>{{ program.type_increase }}</td>
                <td>{{ program.remarks }}</td>
                <td>
                  <span v-if="program.created_at">{{
                    program.created_at | standardDate
                  }}</span>
                </td>
                <td>{{ program.updated_by }}</td>
                <td class="text-center">
                  <button 
                    class="btn btn-sm btn-outline-primary"
                    @click.stop="openUpdateModal(program)"
                    title="Update Status & Remarks"
                  >
                    <i class="bi bi-pencil-square"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          
          <!-- Pagination -->
          <div class="d-flex justify-content-between align-items-center mt-3">
            <div>
              <select v-model="perPage" class="form-control form-control-sm" style="width: 80px">
                <option :value="10">10</option>
                <option :value="25">25</option>
                <option :value="50">50</option>
                <option :value="100">100</option>
              </select>
            </div>
            <div>
              <button 
                class="btn btn-sm btn-outline-secondary mr-2" 
                :disabled="currentPage === 1"
                @click="currentPage--"
              >
                Previous
              </button>
              <span class="mr-2">
                Page {{ currentPage }} of {{ totalPages }}
              </span>
              <button 
                class="btn btn-sm btn-outline-secondary" 
                :disabled="currentPage === totalPages"
                @click="currentPage++"
              >
                Next
              </button>
            </div>
          </div>
        </CCardBody>
      </CCard>
    </div>

    <!-- Add Modal for Update -->
    <div class="modal fade" id="updateModal" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Update Status & Remarks</h5>
            <button type="button" class="close" data-dismiss="modal">
              <span>&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label>Status</label>
              <select v-model="updateForm.status" class="form-control">
                <option value="Pending">Pending</option>
                <option value="Reviewed">Reviewed</option>
                <option value="OnProcess">On Process</option>
                <option value="Evaluated">Evaluated</option>
                <option value="Rejected">Rejected</option>
              </select>
            </div>
            <div class="form-group">
              <label>Remarks</label>
              <textarea 
                v-model="updateForm.remarks" 
                class="form-control" 
                rows="3"
              ></textarea>
            </div>
          </div>
          <div class="modal-footer">
            <button 
              type="button" 
              class="btn btn-secondary" 
              data-dismiss="modal"
            >
              Close
            </button>
            <button 
              type="button" 
              class="btn btn-primary" 
              @click="updateStatus"
              :disabled="updating"
            >
              {{ updating ? 'Updating...' : 'Save Changes' }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: 'Tosf',
  data() {
    return {
      searchValue: '',
      tableLoader: false,
      refreshLoader: false,
      tosfLists: [],
      prefix: 'https://drive.google.com/file/d/',
      currentPage: 1,
      perPage: 10,
      selectedAY: '',
      selectedType: '',
      selectedStatus: '',
      updateForm: {
        id: null,
        status: '',
        remarks: ''
      },
      updating: false
    };
  },
  methods: {      
    refreshData() {
      this.refreshLoader = true;
      this.getTOSFList();
    },

    getTOSFList() {
      this.tableLoader = true;
      axios
        .get('api/tosf/application-list')
        .then((res) => {
          console.log("check TOSF list", res);
          this.tosfLists = res.data;
          if (this.refreshLoader) {
            toastr.success('Data refreshed successfully');
          }
        })
        .catch((err) => {
          console.error('Error fetching TOSF list:', err);
          toastr.error('Failed to fetch data');
        })
        .finally(() => {
          this.tableLoader = false;
          this.refreshLoader = false;
        });
    },

    pushRoute(program) {
      this.$router.push({
        name: 'tosf-requirements',
        params: {
          id: program.id,
          type: program.type_increase,
        },
      });
    },

    openUpdateModal(program) {
      this.updateForm.id = program.id;
      this.updateForm.status = program.status;
      this.updateForm.remarks = program.remarks;
      $('#updateModal').modal('show');
    },

    updateStatus() {
      this.updating = true;
      axios.put(`api/tosf/update-status/${this.updateForm.id}`, this.updateForm)
        .then(response => {
          // Update the local data
          const index = this.tosfLists.findIndex(p => p.id === this.updateForm.id);
          if (index !== -1) {
            this.tosfLists[index].status = this.updateForm.status;
            this.tosfLists[index].remarks = this.updateForm.remarks;
          }
          
          $('#updateModal').modal('hide');
          toastr.success('Status updated successfully');
        })
        .catch(error => {
          console.error('Error updating status:', error);
          toastr.error('Failed to update status');
        })
        .finally(() => {
          this.updating = false;
        });
    },

    exportTosfList() {
      if (!this.selectedAY) {
        toastr.warning('Please select an academic year before exporting.');
        return;
      }

      if (!this.selectedType) {
        toastr.warning('Please select a type of increase before exporting.');
        return;
      }

      axios.get('api/tosf/export-list', {
        params: {
          academic_year: this.selectedAY,
          type: this.selectedType,
        },
        responseType: 'blob' // Important for file download
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'TOSF_List.csv'); // or .xlsx, .csv, etc.
        document.body.appendChild(link);
        link.click();
        link.remove();
        toastr.success('List exported successfully');
      })
      .catch(error => {
        console.error('Error exporting list:', error);
        toastr.error('Failed to export list');
      });
    }
  },
  computed: {
    uniqueAcademicYears() {
      return [...new Set(this.tosfLists.map(item => item.academic_year))].sort();
    },
    filteredList() {
      if (!this.searchValue && !this.selectedAY && !this.selectedType && !this.selectedStatus) {
        return this.tosfLists;
      }
      
      return this.tosfLists.filter(program => {
        const matchesSearch = !this.searchValue || [
          program.name,
          program.hei_code,
          program.contact_person,
          program.email
        ].some(field => 
          field?.toLowerCase().includes(this.searchValue.toLowerCase().trim())
        );

        const matchesAY = !this.selectedAY || 
          program.academic_year === this.selectedAY;

        const matchesType = !this.selectedType || 
          program.type_increase === this.selectedType;

        const matchesStatus = !this.selectedStatus || 
          program.status === this.selectedStatus;

        return matchesSearch && matchesAY && matchesType && matchesStatus;
      });
    },

    paginatedList() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.filteredList.slice(start, end);
    },

    totalPages() {
      return Math.ceil(this.filteredList.length / this.perPage);
    }
  },
  watch: {
    searchValue() {
      this.currentPage = 1;
    },
    perPage() {
      this.currentPage = 1;
    },
    selectedAY() {
      this.currentPage = 1;
    },
    selectedType() {
      this.currentPage = 1;
    },
    selectedStatus() {
      this.currentPage = 1;
    }
  },
  mounted() {
    this.getTOSFList();
  },
};
</script>
  
<style scoped>
#table-of-programs tr {
  cursor: pointer !important;
}

.bi-arrow-clockwise {
  font-size: 1rem;
}

.modal-dialog {
  max-width: 500px;
}

.btn i {
  font-size: 0.875rem;
}
</style>
  